body {
  margin: 0;
  font-family: "Patrick Hand SC", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 { margin-top: 0; margin-bottom: 0;}

.needle {
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  height: 4px;
  width: 2000px;
  background: #000;
  animation: animate 1s linear infinite;
  transform-origin: left;
}

@keyframes animate {
  0% {
    transform: rotate(-90deg);
  }
  
  100% {
    transform: rotate(270deg);
  }
}